import { useState } from "react"
import Modal from "../../modals/Modal"
import CurrencyConverter from "../../modals/CurrencyConverter"
import CurrencyExchangeIcon from "../icons/navigation/CurrencyExchangeIcon"
import ToolTip from "./ToolTip"

const OpenCurrencyExchange = ({ value, style }) => {

    const [ isModalOpen, setIsModalOpen ] = useState(false)  

    const handleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    return (
        <>
        <div style={style} >
            <ToolTip title="Currency exchange" className="tooltip-bottom-right">
                <button onClick={handleModal} aria-label="currency converter">
                    <CurrencyExchangeIcon style={{fontSize: '1rem'}} />
                </button>
            </ToolTip>
        </div>

        <Modal
            isModalVisible={isModalOpen} 
            onBackdropClick={handleModal}   
            content={ 
                <CurrencyConverter value={value} />  
            }
        />
        </>
    )

}

export default OpenCurrencyExchange