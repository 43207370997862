import { cloudinaryURL } from "../../../utils"

const FooterLogo = ({ style, className, text }) => {

    return (

        <div className={`line-thru ${className ? className : ''}`} style={style}>

            <img 
                src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724650654/westcoast/logo/compass-gold_klokrq.png')}
                alt="logo" 
                style={{ width: 100 }} 
            />   

            <h2 className="large-header">{text && text}</h2> 
        
        </div>  
        
    )
}

export default FooterLogo