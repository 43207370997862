import axios from 'axios'
import {     
    NEW_PAGE_REQUEST,
    NEW_PAGE_SUCCESS,
    NEW_PAGE_FAIL,
    UPDATE_PAGE_REQUEST,
    UPDATE_PAGE_SUCCESS,
    UPDATE_PAGE_FAIL,  
    DELETE_PAGE_REQUEST,
    DELETE_PAGE_SUCCESS,
    DELETE_PAGE_FAIL,
    PAGE_DETAILS_REQUEST,
    PAGE_DETAILS_SUCCESS,
    PAGE_DETAILS_FAIL,  
    UPDATE_IMAGE_REQUEST, 
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/homepageConstants'

// Get homeage details
export const getHomepageDetails = () => async (dispatch) => {
    try {

        dispatch({ type: PAGE_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/v1/homepage`)

        dispatch({
            type: PAGE_DETAILS_SUCCESS,
            payload: data.homepage
        })
        
    } catch (error) {

        dispatch({
            type: PAGE_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}

// New homepage (Admin)
export const newHomepage = (pagedData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PAGE_REQUEST })
        const config = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
        const { data } = await axios.post('/api/v1/admin/homepage', pagedData, config)
        dispatch({
            type: NEW_PAGE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_PAGE_FAIL,
            payload: error.response.data.message
        })
    }
}
// Update homepage (Admin)
export const updateHomepage = (pageData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PAGE_REQUEST })
        const config = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
        const { data } = await axios.put(`/api/v1/admin/homepage`, pageData, config)
        dispatch({
            type: UPDATE_PAGE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PAGE_FAIL,
            payload: error.response.data.message
        })
    }
}
// Delete homepage (Admin)
export const deleteHomepage = () => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PAGE_REQUEST })    
        const { data } = await axios.delete(`/api/v1/admin/homepage`)
        dispatch({
            type: DELETE_PAGE_SUCCESS,
            payload: data.success
        })        
    } catch (error) {
        dispatch({
            type: DELETE_PAGE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update images
export const updateImages = (id, initPos, finPos, imgId) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_IMAGE_REQUEST })      

        const { data } = await axios.put(`/api/v1/admin/homepage/image?id=${id}&initPos=${initPos}&finPos=${finPos}&imgId=${imgId}`)

        dispatch({
            type: UPDATE_IMAGE_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: UPDATE_IMAGE_FAIL,
            payload: error.response.data.message
        })

    }
}

// Delete image (Admin)
export const deleteImage = (id, imgIndex, imgId) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_IMAGE_REQUEST })      

        const { data } = await axios.delete(`/api/v1/admin/homepage/image?id=${id}&imgIndex=${imgIndex}&imgId=${imgId}`)

        dispatch({
            type: DELETE_IMAGE_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: DELETE_IMAGE_FAIL,
            payload: error.response.data.message
        })

    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}