import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

const blacklistedPages = ['/booking', '/services', '/information', '/payment', '/final-details']

const MetaData = ({ title, description, noIndex=false }) => {

    const location = useLocation()
    const restricted = blacklistedPages.includes(location.pathname)

    return (
        
        <Helmet>

            <title>{`${title} - ${process.env.REACT_APP_SITE_NAME}`}</title>
            
            <meta
                name="viewport"
                content={restricted 
                    ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' 
                    : 'width=device-width, initial-scale=1.0'
                }
            />
            
            <meta 
                name="description" 
                content={description 
                    ? description.replace(/(<([^>]+)>)/gi, "").substring(0, 155) + '...' 
                    : process.env.REACT_APP_SITE_DESCRIPTION
                } 
            />  

            <meta property="og:title"       content={process.env.REACT_APP_SITE_NAME} />
            <meta property="og:description" content={process.env.REACT_APP_SITE_DESCRIPTION} />
            <meta property="og:image"       content={`${process.env.REACT_APP_DOMAIN}/images/logo-large.png`} />
            <meta property="og:url"         content={process.env.REACT_APP_DOMAIN} />
            <meta property="og:type"        content="website" />  

            {noIndex === true && (
                 <meta name="robots" content="noindex" />
            )}
                     
        </Helmet>

    )

}

export default MetaData