import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { cloudinaryURL } from '../../../utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ArrowBackIcon from '../../layout/icons/arrows/ArrowBackIcon'
import ArrowForwardIcon from '../../layout/icons/arrows/ArrowForwardIcon'
import ZoomInMapIcon from '../../layout/icons/zoom/ZoomInMapIcon'
import ZoomInIcon from '../../layout/icons/zoom/ZoomInIcon'
import ZoomOutIcon from '../../layout/icons/zoom/ZoomOutIcon'
import CircularProgress from '../../layout/tools/circularProgress/CircularProgress'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './lightbox.css'

const Lightbox = ({ images, altTag, currentIndex, setCurrentIndex }) => {
   
    const handelRotation = (dir) => {               
        const newIndex = dir === 'right' ? currentIndex + 1 : currentIndex - 1
        setCurrentIndex(newIndex)
    }

    return (
       
        <div className="lightbox">   

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
            </div>          

            <TransformWrapper initialScale={1}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>  
                    <div className="lightbox-image">     

                        <TransformComponent>
                           
                            <LazyLoadImage
                                src={cloudinaryURL(images[currentIndex].url)} 
                                alt={images[currentIndex].title || altTag}  
                                effect="blur"
                                placeholderSrc={images[currentIndex].thumb_url ? cloudinaryURL(images[currentIndex].thumb_url) : cloudinaryURL(images[currentIndex].url)}                               
                                style={{ 
                                    width: 'auto',
                                    maxWidth: '90vw',
                                    height: 'auto',
                                    maxHeight: 'calc(100vh - 100px)'
                                }}   
                            />    
                                                
                        </TransformComponent>

                    </div>

                    <div className="direction-controls">

                        <button 
                            className={`icon-button ${currentIndex === 0 && 'pointer-events-none'}`}
                            aria-label="back"
                            onClick={() => handelRotation('left')}
                        >
                            <ArrowBackIcon />
                        </button>
                    
                        <button 
                            className={`icon-button ${currentIndex === (images.length - 1) && 'pointer-events-none'}`}
                            aria-label="next" 
                            onClick={() => handelRotation('right')}
                        >
                            <ArrowForwardIcon />
                        </button>

                    </div>

                    <div className="lightbox-controls">

                        <small>{currentIndex + 1} / {images.length}</small>

                        <small>{images[currentIndex].title || altTag}</small>

                        <div className="d-flex" style={{ gap: 5 }}>

                            <button 
                                className="icon-button" 
                                aria-label="zoom in" 
                                onClick={() => zoomIn()}
                            >
                                <ZoomInIcon />
                            </button>   

                            <button 
                                className="icon-button"
                                aria-label="zoom out"  
                                onClick={() => zoomOut()}
                            >
                                <ZoomOutIcon />
                            </button> 

                            <button 
                                className="icon-button"
                                aria-label="reset zoom" 
                                onClick={() => {resetTransform()}}
                            >
                                <ZoomInMapIcon />
                            </button>                            

                        </div>

                    </div>    

                    <div className="lightbox-description">

                        <small>{images[currentIndex].description || ''}</small>

                    </div>                                    
                    </>
                )}
            </TransformWrapper>  

        </div>

  )

}

export default Lightbox