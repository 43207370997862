import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { cloudinaryURL, scrollAnimation } from '../../../utils'
import Modal from '../../modals/Modal'
import Lightbox from '../lightbox/Lightbox'
import Thumbnails from '../thumbnails/Thumbnails'

export const ScrollableTiles = ({ data }) => {

    const isMobile = useMediaQuery({ query: `(max-width: 501px)` })

    const [ currentIndex,   setCurrentIndex   ] = useState(0)
    const [ isLightboxOpen, setIsLightboxOpen ] = useState(false)

    useEffect(() => {
        scrollAnimation()
    })

    const handleClick = (i) => {
        setCurrentIndex(i)
        setIsLightboxOpen(!isLightboxOpen)
    }

    return (
        <>
        {isMobile ? (
            <Thumbnails data={data} />
        ) : (
            <div className="d-flex gap-1 flex-wrap" style={{ marginTop: 40 }}>
                {data.slideshowImages.map((img, index) => (
                    <figure key={index} 
                        className="hover-zoom fade-in zoom-in"
                        style={{ flex: '1 0 240px', height: 180 }}
                    >
                        <img 
                            className="object-fit" 
                            src={cloudinaryURL(img.med_url)} 
                            alt={img.title || 'West Coast Divers'} 
                            style={{ borderRadius: 10 }}
                            onClick={() => handleClick(index)} 
                        />
                    </figure>
                ))}    
            </div>
        )}        

        <Modal
            isModalVisible={isLightboxOpen}
            onBackdropClick={() => setIsLightboxOpen(!isLightboxOpen)}
            fullWidth={true}               
            content={
                <Lightbox
                    images={data.slideshowImages} 
                    isLightboxOpen={isLightboxOpen}
                    setIsLightboxOpen={setIsLightboxOpen}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            }
        />
        </>
    )

}
