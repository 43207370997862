import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import EditOutlinedIcon from '../icons/actions/EditOutlinedIcon'
import ToolTip from './ToolTip'

const EditPageLink = ({ link, style }) => {

    const { user } = useSelector( state => state.auth )  

    if (!user || !user.role === 'admin') return

    return (       
        <Link 
            to={`/admin/${link}`}             
            style={style}
        >
            <ToolTip title="edit">
                <small className="d-flex align-items-center gap-1">
                    <EditOutlinedIcon /> Edit  
                </small>
            </ToolTip>                     
        </Link>  
    )

}

export default EditPageLink