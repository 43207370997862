import { useEffect, useState } from 'react'

const CompanyAgeDecal = () => {

    const [ companyAge, setCompanyAge ] = useState(0)

    useEffect(() => {
        const startDate   = new Date(1996, 0, 1)
        const currentDate = new Date()        
        const yearsDiff   = currentDate.getFullYear() - startDate.getFullYear()    

        const hasAnniversaryPassed =
            currentDate.getMonth()      >  startDate.getMonth() 
            || (currentDate.getMonth() === startDate.getMonth() 
            && currentDate.getDate()    >= startDate.getDate())
    
        setCompanyAge(hasAnniversaryPassed ? yearsDiff : yearsDiff - 1)
      }, [])

    return (

        <div className="line-thru" style={{ margin: '60px 0 80px 0' }}>
            <div 
                className="background-cover d-flex align-items-center justify-content-center"
                style={{  width: 140, height: 120, backgroundImage: 'url("/images/27-years.png")' }}
            >
                <div className="d-flex align-items-center justify-content-center direction-column" style={{ position: 'relative', top: -10, left: 4 }}>
                    <span style={{ fontSize: 36, lineHeight: '1em' }}>{companyAge}</span>

                    <span style={{ fontSize: 16, lineHeight: '1em' }}>years</span>
                </div>
                
            </div>                        
        </div>

    )

}

export default CompanyAgeDecal