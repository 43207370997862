import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { cloudinaryURL, formatPrice, scrollAnimation } from '../../utils'
import KeyboardArrowRightIcon from '../layout/icons/arrows/KeyboardArrowRightIcon'
import '../layout/grid/grid.css'

const GridEight = ({ items }) => {

    useEffect(() => {
        scrollAnimation()
    }) 

    return (
        <>       
        <div className="grid grid-eight">

            {items && items.map((item, i) => (
                <div key={i} className="fade-in">
                
                    <img 
                         src={cloudinaryURL(item.image.url)}
                        alt={item.name} 
                        mode="fit"
                        width="800"
                    />

                    <div className="grid-content">
                        <h2>{item.name}</h2>  

                        <p>
                            from  <b>{formatPrice(item.cost[0].price)} THB</b>
                        </p>
                        
                        <Link className="fade-in" to={`/course/${item.slug}`}>
                            <button className="button" size="small">
                                Details &nbsp; <KeyboardArrowRightIcon />
                            </button>
                        </Link>
                    </div>    

                </div>
            ))}  

        </div>
        </>
    )

}

export default GridEight