import { Link } from 'react-router-dom'
import HomeIcon from './layout/icons/navigation/HomeIcon'

const Error404 = () => {

    return (
        <>       
        <div 
            className="background-cover container d-flex direction-column align-items-center justify-content-center"
            style={{ backgroundImage: 'url("https://res.cloudinary.com/dvzjiqoxr/image/upload/v1701871523/westcoast/pages/_DSC4555-E-2_w9ypz6.jpg")' }}
        >

            <div className="wrapper text-center">

                <h1 style={{ fontSize: 'clamp(40px, 10vw, 80px', color: 'whitesmoke' }}>Woops! 404</h1>

                <h2 style={{ fontSize: 'clamp(24px, 4.5vw, 42px)', color: '#999' }}>Page not found!</h2>

                <Link to="/" style={{ display: 'inline-block' }}>

                    <button className="button booking-button">

                        <HomeIcon style={{ color: 'white' }} /> &nbsp; Home

                    </button>

                </Link>

            </div>
            
        </div>
        </>
    )

}

export default Error404