import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { cloudinaryURL, scrollAnimation } from '../../../utils'
import KeyboardArrowRightIcon from '../icons/arrows/KeyboardArrowRightIcon'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './grid.css'

const GridFour = ({ items }) => {

    useEffect(() => {
        scrollAnimation()
    }) 

    return (
        <>       
        <div className="grid grid-four">

            {items && items.map((item, i) => (

                <div key={i} className="fade-in">   

                    <LazyLoadImage
                        src={cloudinaryURL(item.image.med_url)} 
                        alt={item.title} 
                        effect="blur"
                        placeholderSrc={cloudinaryURL(item.image.thumb_url)}
                        className="object-fit" 
                    />                  

                    <div className="grid-content">
                        <h2>{item.title}</h2>  

                        <p>{item.subTitle}</p>
                        
                        <Link className="fade-in" to={`/${item.link}`}>
                            <button className="button">
                                Browse &nbsp; <KeyboardArrowRightIcon />
                            </button>
                        </Link>
                    </div>    

                </div>

            ))}  

        </div>
        </>
    )

}

export default GridFour