import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHomepageDetails } from '../actions/homepageActions'
import { getRandomReviews } from '../actions/reviewActions'
import { getCourses } from '../actions/courseActions'
import { ScrollableTiles } from './images/ScrollableTiles/ScrollableTiles'
import { cloudinaryURL } from '../utils'
import { Link } from 'react-router-dom'
import Thumbnails        from './images/thumbnails/Thumbnails'
import Parallax          from './images/Parallax'
import Reviews           from './review/Reviews'
import MetaData          from './layout/MetaData'
import GridFour          from './layout/grid/GridFour'
import Loader            from './layout/tools/Loader'
import VideoPlayer       from './layout/tools/VideoPlayer'
import GridEight         from './courses/GridEight'
import FooterLogo        from './layout/tools/FooterLogo'
import Offer             from './layout/tools/Offer'
import CalendarMonthIcon from './layout/icons/navigation/CalendarMonthIcon'
import EditPageLink      from './layout/tools/EditPageLink'
import RichtextOutput    from './layout/richtext/RichtextOutput'
import CompanyAgeDecal   from './layout/tools/CompanyAgeDecal'
import Banner            from './Banner'

const Home = () => {   

    const dispatch = useDispatch()
    const { loading, homepage } = useSelector( state => state.homepageDetails )
    const { reviews           } = useSelector( state => state.randomReviews )
    const { courses           } = useSelector( state => state.courses )   

    useEffect(() => {   
        dispatch(getHomepageDetails()) 
        dispatch(getCourses())
        dispatch(getRandomReviews())        
    }, [dispatch])  
   
    return (
        <>
        <MetaData title={'Home'} />
  
        {homepage.slideshowImages && (
            <Banner 
                title={'West Coast Divers'}
                subTitle={'Liveaboard Diving, Thailand'}
                height='65vh'
                images={homepage.slideshowImages}
                ticker
            />
        )}  

        <div>

            {loading ? <Loader hideProgress /> : (    
                <>                
                <div className="container wrapper relative d-flex direction-column gap-4" style={{ marginBottom: 40 }}>

                    <EditPageLink link="home" style={{ position: 'absolute', top: 8 }} />                                      

                    <GridFour items={homepage.general?.callToActions} /> 

                </div>

                <div>

                    <div className="container d-flex direction-column gap-4" style={{ marginBottom: 80 }}>

                        <FooterLogo text={homepage.boats?.title} />

                        <div className="wrapper-x d-flex direction-column gap-4">

                            <div className="d-flex direction-column gap-4">

                                {homepage.boats?.description && (
                                    <div className="wrapper-x paragraph-text">                                        
                                        <RichtextOutput text={homepage.boats.description} />                                         
                                    </div>
                                )}  

                                {homepage.boats?.slideshowImages && (
                                    <Thumbnails className="responsive" data={homepage.boats} />   
                                )} 

                            </div>

                            {homepage.general?.description && (
                                <div className="wrapper-x paragraph-text d-flex direction-column gap-2">
                                    <RichtextOutput text={homepage.general.description} />
                                    <Offer />
                                </div>
                            )}   

                            {homepage.general?.slideshowImages && (                                
                                <ScrollableTiles data={homepage.general} />
                            )} 
                                                
                        </div>                              

                    </div>

                    <div style={{ background: 'var(--tertiary-color', paddingTop: 40, paddingBottom: 40 }}>

                        <h2 className="large-header line-thru" style={{ color: 'orange', marginBottom: 40 }}>
                            {homepage.videos?.title}
                        </h2>
                        <p className="wrapper-x paragraph-text text-center" style={{ color: '#ccc', fontSize: 18 }}>
                            {homepage.videos?.description}
                        </p>
                        <VideoPlayer videoIds={homepage.videos?.videos} />

                        <Link to="/boats">
                            <button className="button transparent-button">
                                View our boats
                            </button>
                        </Link>

                    </div>   

                    {homepage.general?.parallaxImages && (
                        <Parallax item={homepage.general.parallaxImages[0]} />
                    )}   
                    <div className="container d-flex direction-column gap" style={{ marginBottom: 80 }}>                         

                        <FooterLogo text={homepage.food?.title} style={{ marginTop: 60, marginBottom: 40 }} />

                        {homepage.food && (
                            <div className="wrapper-x d-flex direction-column gap-4">
                                {homepage.food?.description && (
                                    <div className="wrapper-x paragraph-text">
                                        <RichtextOutput text={homepage.food.description} />
                                    </div>
                                )} 
                                <Thumbnails className="responsive" data={homepage.food} /> 
                            </div>
                        )}

                        <img 
                            src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724650654/westcoast/logo/hot-food_tabjqi.png')} 
                            alt="Thai food" 
                            style={{ width: 300, margin: '40px auto 0 auto', opacity: .1 }} 
                        />

                    </div>       

                    {homepage.general?.parallaxImages && (
                        <Parallax item={homepage.general.parallaxImages[1]} alignLeft />
                    )}              

                    <div style={{ background: 'var(--tertiary-color', paddingTop: 40, paddingBottom: 40 }}>

                        {homepage.equipment && (
                            <>
                            <h2 className="large-header line-thru" style={{ color: 'orange', marginBottom: 40 }}>{homepage.equipment.title}</h2>

                            <div className="wrapper-x paragraph-text d-flex direction-column gap-4" style={{ color: '#ccc' }}>
                                {homepage.equipment?.description && (
                                    <div 
                                        className="dark"
                                    >
                                        <RichtextOutput text={homepage.equipment.description} />
                                    </div>
                                )} 
                                <Thumbnails data={homepage.equipment} /> 
                            </div>

                            <div className="container parent align-items-center" style={{ margin: '60px auto' }}>

                                <div className="wrapper">

                                    <h2 
                                        className="large-header" 
                                        style={{ width: '67%', color: 'orange', textTransform: 'uppercase', fontSize: 'clamp(40px, 6vw, 69px)', fontWeight: 'bold' }}
                                    >
                                        Free Nitrox!
                                    </h2>   

                                    <h3 style={{ fontSize: 'clamp(20px, 6vw, 35px)', color: '#999', margin: '15px 0', display: 'block' }}>Take your diving to the next level...</h3>                         

                                </div>                                

                                <p className="wrapper mobile-fullwidth text-center" style={{ width: '33%', color: '#ccc' }}>

                                    <img
                                        src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1704184847/westcoast/equipment/nitrox_c1bgre.png')}
                                        alt={'Free Nitrox!'}
                                        style={{ width: 100, display: 'block', margin: '0 auto' }}
                                    />

                                    <small>* Certified divers only</small>
                                </p>

                            </div>

                            
                            </>
                        )}   

                    </div>   

                    {homepage.general?.parallaxImages && (
                        <Parallax item={homepage.general.parallaxImages[2]} />
                    )}  

                    <FooterLogo style={{ margin: '80px 0 0 0' }} text={homepage.courses?.title} />

                    <div className="container wrapper" style={{ paddingBottom: 80 }}>                        
  
                        {homepage.courses?.description && (
                            <div className="wrapper-x paragraph-text" style={{ marginBottom: 40 }}>
                                <RichtextOutput text={homepage.courses.description} />
                            </div>
                        )} 

                        {courses && (
                            <GridEight items={courses} />
                        )}       

                    </div>  

                    {homepage.general?.parallaxImages && (
                        <Parallax item={homepage.general.parallaxImages[3]} alignLeft />
                    )}  
                        
                    {reviews && reviews.length && (   
                        <div style={{ background: 'var(--tertiary-color', margin: '0 0 40px 0' }}>                      
                            <Reviews data={reviews} />
                        </div>
                    )} 

                    {homepage.footer?.slideshowImages && (
                        <div className="container wrapper">
                            <Thumbnails className="responsive" data={homepage.footer} /> 
                        </div>
                    )}  

                    {homepage.footer?.description && (
                        <div
                            className="wrapper paragraph-text"
                            style={{ fontSize: 20, color: '#757575', marginTop: 80 }}
                        >
                            <b style={{ color: 'var(--secondary-color)', fontSize: 50, fontFamily: 'Times New Roman' }}>
                                “
                            </b>
                                <RichtextOutput text={homepage.footer.description} />
                            <b 
                                className="float-r"
                                style={{ color: 'var(--secondary-color)', fontSize: 50, fontFamily: 'Times New Roman', lineHeight: 0, position: 'relative', top: 10, left: 5 }}
                            >
                                ”
                            </b>
                        </div>
                    )} 
              
                    <CompanyAgeDecal />

                    <Link 
                        to={`/trip-schedules`} 
                        className="sticky float-r"
                        style={{ bottom: 56, right: 6, marginBottom: 20}}
                    >

                        <button 
                            className="button button-secondary gap-1" 
                            style={{ margin: 0 }}
                        >
                            <CalendarMonthIcon style={{ color: 'white' }} />
                            Book Now!

                        </button>

                    </Link> 

                </div>
                </>
            )}            

        </div>  
        </>
    )
}

export default Home