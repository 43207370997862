import axios from 'axios'
import { 
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    RANDOM_REVIEWS_REQUEST,
    RANDOM_REVIEWS_SUCCESS,
    RANDOM_REVIEWS_FAIL,
    GET_ADMIN_REVIEWS_REQUEST,
    GET_ADMIN_REVIEWS_SUCCESS,
    GET_ADMIN_REVIEWS_FAIL,
    GET_PENDING_REVIEWS_REQUEST,
    GET_PENDING_REVIEWS_SUCCESS,
    GET_PENDING_REVIEWS_FAIL,
    ADMIN_REVIEW_DETAILS_REQUEST,
    ADMIN_REVIEW_DETAILS_SUCCESS,
    ADMIN_REVIEW_DETAILS_FAIL,
    UPDATE_ADMIN_REVIEW_REQUEST,
    UPDATE_ADMIN_REVIEW_SUCCESS,
    UPDATE_ADMIN_REVIEW_FAIL,
    GET_ALL_REVIEWS_REQUEST,
    GET_ALL_REVIEWS_SUCCESS,
    GET_ALL_REVIEWS_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    CLEAR_ERRORS 
} from '../constants/reviewConstants'

// Add new review
export const newReview = (reviewData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_REVIEW_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'   
            }
        }

        const { data } = await axios.put('/api/v1/review', reviewData, config)

        dispatch({
            type: NEW_REVIEW_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: NEW_REVIEW_FAIL,
            payload: error.response.data.message
        })

    }
}

// Update Review Admin
export const updateReview = (id, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ADMIN_REVIEW_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/v1/admin/review/${id}`, formData, config)
        dispatch({
            type: UPDATE_ADMIN_REVIEW_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_ADMIN_REVIEW_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get random reviews 
export const getRandomReviews = (boat = '') => async (dispatch) => {
    
    try {

        dispatch({ type: RANDOM_REVIEWS_REQUEST })     

        const { data } = await axios.get(`/api/v1/reviews/random?keyword=${boat}`)

        dispatch({
            type: RANDOM_REVIEWS_SUCCESS,
            payload: data.reviews
        })
        
    } catch (error) {

        dispatch({
            type: RANDOM_REVIEWS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get all reviews 
export const getAllReviews = ( currentPage = 1, rating = 0, keyword = '', filter = 'new' ) => async (dispatch) => {
    try {

        dispatch({
            type: GET_ALL_REVIEWS_REQUEST
        })       
       
        let link = `/api/v1/reviews?page=${currentPage}&keyword=${keyword}&filter=${filter}`

        if (rating > 0) {
            link = `/api/v1/reviews?page=${currentPage}&rating=${rating}&keyword=${keyword}&filter=${filter}`
        }

        const { data } = await axios.get(link)

        dispatch({
            type: GET_ALL_REVIEWS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: GET_ALL_REVIEWS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get all reviews - Admin
export const getAdminReviews = () => async (dispatch) => {
    try {

        dispatch({
            type: GET_ADMIN_REVIEWS_REQUEST
        })

        const { data } = await axios.get(`/api/v1/admin/reviews`)

        dispatch({
            type: GET_ADMIN_REVIEWS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: GET_ADMIN_REVIEWS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get pending reviews - Admin
export const getPendingReviews = () => async (dispatch) => {
    try {

        dispatch({
            type: GET_PENDING_REVIEWS_REQUEST
        })

        const { data } = await axios.get(`/api/v1/admin/pending`)

        dispatch({
            type: GET_PENDING_REVIEWS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: GET_PENDING_REVIEWS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get Single Review Details
export const getReviewDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_REVIEW_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/review/${id}`)

        dispatch({
            type: ADMIN_REVIEW_DETAILS_SUCCESS,
            payload: data.review
        })
        
    } catch (error) {

        dispatch({
            type: ADMIN_REVIEW_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Delete Review - (Admin)
export const deleteReview = (id) => async (dispatch) => {
    try {

        dispatch({
            type: DELETE_REVIEW_REQUEST
        })

        const { data } = await axios.delete(`/api/v1/review?id=${id}`)

        dispatch({
            type: DELETE_REVIEW_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: DELETE_REVIEW_FAIL,
            payload: error.response.data.message
        })

    }
}
// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}