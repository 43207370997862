const VideoPlayer = ({ videoIds }) => {
    
    return (

        <div className="container wrapper d-flex gap-2 flex-wrap">

            {videoIds && videoIds.map((videoId, index) => (              
                <iframe
                    key={index} 
                    title={`West Coast Divers: ${index}`} 
                    src={`https://www.youtube-nocookie.com/embed/${videoId.id}?mute=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    sandbox="allow-same-origin allow-scripts allow-presentation"
                    loading="lazy"
                    style={{ width: '100%', flex: '1 1 300px', height: 200  }}
                    role="presentation" 
                />
            ))}

        </div>

    )

}

export default VideoPlayer