import { useEffect, useRef } from 'react'
import ScubaDivingIcon from "../../icons/navigation/ScubaDivingIcon"
import './ticker.css'

const Ticker = ({ style }) => {

    const tickerRef = useRef(null)

    const currentYear  = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const nextSeason = currentMonth <= 7 
        ? currentYear 
        : currentYear + 1

    const text = `Season ${nextSeason} - ${nextSeason + 1}, 10% discount, valid until 31st August ${nextSeason} ... Season ${nextSeason + 1} - ${nextSeason + 2}, 10% discount, valid until 31st August ${nextSeason + 1}`

    useEffect(() => {
        const tickerElement = tickerRef.current
    
        const animationDuration = (text.length / 25) * 10 
        tickerElement.style.animationDuration = `${animationDuration}s`

        return () => {
            tickerElement.style.animation = 'none'
            void tickerElement.offsetWidth 
            tickerElement.style.animation = `ticker ${animationDuration}s linear infinite`
        }
        
    }, [text])    

    return (       
       
        <div className="ticker-container">

            <div className="ticker" ref={tickerRef} style={style}>

                <ScubaDivingIcon /> {text}
            
            </div>

        </div>

    )

}

export default Ticker