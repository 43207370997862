import { useEffect, useState } from 'react'
import { formatMyDateTime } from '../../utils'
import { Link } from 'react-router-dom'
import RichtextOutput from '../layout/richtext/RichtextOutput'
import ToolTip from '../layout/tools/ToolTip'
import KeyboardArrowLeftIcon from '../layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from '../layout/icons/arrows/KeyboardArrowRightIcon'
import ReadMoreIcon from '../layout/icons/actions/ReadMoreIcon'
import Rating from '../layout/tools/Rating'
import './review.css'

const Reviews = ({ data, hideBar }) => {    

    const [ imgIndex,   setImgIndex   ] = useState(0)     
    const [ left,       setLeft       ] = useState('-100%')
    const [ imageArray, setImageArray ] = useState([])
    const [ openText,   setOpenText   ] = useState([])

    const handleText = (i) => {        
        let array = [...openText]        
        array[i] = !openText[i]  
        setOpenText(array)
    }

    const cloneData = (data) => {
        let array = []
        array.push(data[data.length - 1])
        data.forEach((d) => {            
            array.push(d)
        })          
        array.push(data[0])
        setImageArray(array)
    }

    const resetClasses = (elements, add) => {
        for ( let i = 0; i < elements.length; i++ ) {
            if ( add === true ) {
                elements[i].classList.add('transition')  
            } else {
                elements[i].classList.remove('transition') 
            }                                  
        }
    }

    const handleMove = ( dir ) => {
        setOpenText([])
        const item      = document.querySelector( '.reviews li' )
        const slides    = document.querySelectorAll('.transition')
        const width     = item.offsetWidth * -1         
        if ( dir === 'left' ) {
            setImgIndex( imgIndex - 1 )
            setLeft    ( ( imgIndex  * width ) + 'px' )
            if ( imgIndex === 0 ) {
                setTimeout(() => {
                    resetClasses(slides, false)  

                    setImgIndex( data.length - 1 )
                    setLeft    ( ( data.length * width ) + 'px' )

                    setTimeout(() => {
                        resetClasses(slides, true)
                    }, 50)   
                }, 500) 
            }
        } else {
            setImgIndex( imgIndex + 1 )
            setLeft ( ( imgIndex + 2 ) * width + 'px' )
            if ( imgIndex === (data.length - 1) ) {  
                setTimeout(() => {
                    resetClasses(slides, false)                  
                    setImgIndex( 0 )
                    setLeft    ( '-100%' )
                    setTimeout(() => {
                        resetClasses(slides, true)
                    }, 50)   
                }, 500)  
            }            
        }       
    } 

    useEffect(() => {         
        cloneData(data)
        const button = document.querySelector('.arrow-buttons button:first-child')    
        window.addEventListener('resize', () => {
            button.click()
        })
    }, [ data ])  
    
    return (

        <div className="wrapper relative text-center">

            <h2 
                className="large-header line-thru"
                style={{ color: 'orange' }}>
                Guest Reviews
            </h2>

            <p style={{ color: '#ccc', fontSize: 18 }}>See what our guests have said about our trips</p>
            
            <Link 
                to="/similan-islands-liveaboard-reviews"
                style={{ display: 'inline-block', margin: '0 auto 40px auto' }}
            >
                <button className="button" style={{ padding: '7px 15px', borderRadius: 5 }}>
                    See all reviews
                </button>
            </Link>
           
            <div className="relative" style={{ maxWidth: '800px', margin: '0 auto' }}>

                <div style={{ overflow: 'hidden', border: 'solid 1px var(--secondary-color)', color: '#999', width: 'calc(100% - 40px)', margin: '0 auto' }}>
                    
                    <ul style={{ left: left }} 
                        className="relative reviews transition whitespace-nowrap"
                    >    

                        {imageArray && imageArray.map((slide, index) => (
                            <li 
                                key={index}
                                className={index === (imgIndex + 1) ? 'active' : ''}
                                style={{ 
                                        display: 'inline-block', 
                                        verticalAlign: 'top',
                                        whiteSpace: 'normal',
                                        padding: 20, width: '100%'
                                }}
                            > 
                                
                                <div className="d-flex" style={{ gap: '20px', marginBottom: '10px' }}>
                                    <img
                                        src={slide.avatar.url ? slide.avatar.url : '/images/default-avatar.jpg'} 
                                        alt={slide.name} 
                                        style={{ width: 40, height: 40, borderRadius: '50%' }}
                                    />
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <Rating ratings={slide.rating} />                                           
                                        </div>

                                        <small>
                                            <b>{slide.name}</b> on {formatMyDateTime(slide.reviewCreatedAt)}
                                        </small>
                                    </div>                                    
                                </div> 

                                <div style={{ minHeight: 100, marginTop: 20 }}>
                                {openText && openText[index] 
                                    ? <RichtextOutput text={`${slide.comment}`} /> 
                                    : <RichtextOutput text={`${slide.comment.substring(0, 501)}...`} />
                                }     
                                </div>                                 

                                <div className="text-right">
                                    <ToolTip 
                                        className="tooltip-bottom-right"
                                        title={!openText[index] ? 'Read more' : 'Read less'}
                                    >
                                        <button onClick={() => handleText(index)}>
                                                {openText[index]
                                                    ? <ReadMoreIcon aria="Read less" style={{ transform: 'scaleX(-1)' }} />
                                                    : <ReadMoreIcon aria="Read more" />}
                                        </button>
                                    </ToolTip>
                                </div>
                            </li>
                        ))}                              
                    </ul>
                </div>

                <div className="arrow-buttons">                
                    <button 
                        className="icon-button arrow" 
                        onClick={() => handleMove('left')} 
                        aria-label="left"
                    >
                        <KeyboardArrowLeftIcon />
                    </button>     

                    <button 
                        className="icon-button arrow" 
                        onClick={() => handleMove('right')} 
                        aria-label="right"
                    >
                        <KeyboardArrowRightIcon />
                    </button>
                </div>

            </div>   

            <ul className="pagers d-flex justify-content-center" style={{ marginTop: '20px', gap: '5px' }}>
                {data && data.map((d, i) => (
                    <li 
                        key={i}                             
                        style={{ background: i === imgIndex ? 'var(--primary-color)' : '#ccc' }}
                    />
                ))}
            </ul>
        </div>           

    )

}

export default Reviews
