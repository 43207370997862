import { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile, loadUser, clearErrors } from '../../actions/userActions'
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import { validateEmail } from '../../utils'
import Loader from '../layout/tools/Loader'
import Input from '../layout/forms/Input'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import HomeIcon from '../layout/icons/navigation/HomeIcon'
import Select from '../layout/forms/Select'
const data = [
    {
        label: 'Mr',
        value: 'Mr'
    },
    {
        label: 'Mrs',
        value: 'Mrs'
    },
    {
        label: 'Ms',
        value: 'Ms'
    },
]

const UpdateProfile = ({ setIsUpdateProfileVisible, setIsProfileVisible, setIsUpdatePasswordVisible }) => {

    const alert = useAlert()    
    const dispatch = useDispatch()

    const { user                      } = useSelector( state => state.auth )
    const { loading, isUpdated, error } = useSelector( state => state.user )

    const [ title,         setTitle         ] = useState('')
    const [ firstName,     setFirstName     ] = useState('')
    const [ lastName,      setLastName      ] = useState('')
    const [ email,         setEmail         ] = useState('')
    const [ avatar,        setAvatar        ] = useState('')
    const [ avatarPreview, setAvatarPreview ] = useState('/images/default-avatar.jpg')
    
    useEffect(() => {
        if(user) {
            setTitle(user.title)  
            setFirstName(user.firstName)  
            setLastName(user.lastName)  
            setTitle(user.title)  
            setEmail(user.email)    
            if (user.avatar) {
                setAvatarPreview(user.avatar.url) 
            }   
        }
        if(error) { 
            alert.error(error)
            dispatch(clearErrors())
        }
        if(isUpdated) {
            alert.success('User updated')
            dispatch(loadUser())
            dispatch({ type: UPDATE_PROFILE_RESET })
        }
    }, [dispatch, user, alert, isUpdated, error])

    const submitHandler = (e) => {        
        e.preventDefault()
        const formData = new FormData()
        formData.set('title', title)       
        formData.set('firstName', firstName)       
        formData.set('lastName', lastName)       
        formData.set('email', email)
        formData.set('avatar', avatar)
        dispatch(updateProfile(formData))
    } 

    const onChange = (e) => { 
        const reader = new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setAvatarPreview(reader.result)
                setAvatar(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const handleProfile = () => {
        setIsProfileVisible(true)
        setIsUpdateProfileVisible(false)
    }

    const handleUpdate = () => {
        setIsUpdateProfileVisible(false)
        setIsUpdatePasswordVisible(true)
    }

    return (
        <>  
        {loading ? <Loader /> : ( 
            
            <form className="wrapper" onSubmit={submitHandler}>
                
                <h3>Update Profile</h3>                

                <div className="d-flex direction-column gap-2" style={{ flex: 1 }}>
                   
                    <div className="d-flex gap-2 align-items-center"> 

                        <label>                            
                            <input
                                type="file"  
                                style={{ display: 'none' }}
                                name="avatar"                            
                                onChange={onChange}                                                                                                                             
                            />
                            <img 
                                src={avatarPreview} 
                                alt="Avatar Preview" 
                                className="cursor-pointer"
                                style={{ width: 50, height: 50, borderRadius: '50%' }}
                            />                              
                        </label>

                        <div style={{ flex: 1 }}>

                            <Select
                                label="Title"
                                data={data}
                                value={title}
                                onChange={setTitle}
                                filled={title}
                                required
                            />

                        </div>

                    </div>   
                        
                    <Input 
                        name="firstName"
                        placeholder="First name"
                        value={firstName} 
                        onChange={(e) => setFirstName(e.target.value)}
                        filled={firstName}
                        required
                    />

                    <Input 
                        name="lastName"
                        placeholder="Last name"
                        value={lastName} 
                        onChange={(e) => setLastName(e.target.value)}
                        filled={lastName}
                        required
                    />

                    <Input 
                        name="email"
                        placeholder="Email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        filled={validateEmail(email)}
                        required
                    /> 

                </div>                                                        

                <button
                    type="submit"                             
                    className="button booking-button"
                    disabled={!title || !firstName || !lastName || !validateEmail(email)}
                >
                    Update
                </button>   

                <div className="d-flex justify-content-between"> 
                    
                    <small className="d-flex align-items-center justify-content-end">                            
                        <button className="icon-button" onClick={handleUpdate}>
                            <EditOutlinedIcon />
                        </button>
                        &nbsp; 
                        Update Password
                    </small>   

                    <small className="d-flex align-items-center justify-content-end">                            
                        &nbsp; 
                        Profile
                        <button className="icon-button" onClick={handleProfile}>
                            <HomeIcon />
                        </button>                       
                    </small>                     
                                                                        
                </div>                            

            </form>
        )}
        </>
    )

}

export default UpdateProfile
