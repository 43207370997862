import { useState, useEffect  } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, register } from '../../actions/userActions'
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from '../layout/tools/Loader'
import Input from '../layout/forms/Input'
import LoginIcon from '../layout/icons/login/LoginIcon'
import Select from '../layout/forms/Select'
import PersonAddAltIcon from '../layout/icons/people/PersonAddAltIcon'
import { isPasswordValid, validateEmail } from '../../utils'

const data = [
    {
        label: 'Mr',
        value: 'Mr'
    },
    {
        label: 'Mrs',
        value: 'Mrs'
    },
    {
        label: 'Ms',
        value: 'Ms'
    },
]

const Register = ({ setIsLoginVisible, setIsRegisterVisible, redirect, setRedirect }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const { loading, isAuthenticated, error } = useSelector( state => state.auth )
   
    const [ avatar,        setAvatar        ] = useState('') 
    const [ avatarPreview, setAvatarPreview ] = useState('/images/default-avatar.jpg')     
    const [ user, setUser ] = useState({ 
        title: '', 
        firstName: '', 
        lastName: '', 
        email: '', 
        password: '' 
    })     
    const { title, firstName, lastName, email, password } = user    
    const [ captcha, setCaptcha ] = useState(false)    

    useEffect(() => {        
        if(isAuthenticated) {    
            setCaptcha(false)               
            alert.success(`Registration complete`)   
            if (setRedirect) setRedirect(redirect)  
            setIsRegisterVisible(false)                     
        }
        if(error) {  
            alert.error(error)
            dispatch(clearErrors())
            setCaptcha(false) 
        }
    }, [dispatch, setIsRegisterVisible, redirect, setRedirect, alert, user, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault()  
        window.scroll(0,0)       
        setCaptcha(true)                  
    }

    const handleLogin = () => {
        setIsLoginVisible(true)
        setIsRegisterVisible(false)
    }

    const handleTitle = (value) => {
        setUser({ ...user, title: value })
    }

    const handleChange = (value) => {  
        const formData = new FormData()
        formData.set('title',     title)
        formData.set('firstName', firstName)
        formData.set('lastName',  lastName)
        formData.set('email',     email)
        formData.set('password',  password)
        formData.set('avatar',    avatar)
        formData.set('key',       value)        
        dispatch(register(formData))
    }

    const onChange = (e) => {
        if(e.target.name === 'avatar') {
            const reader = new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)                    
                }
            }
            reader.readAsDataURL(e.target.files[0])
        } else {
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }    

    return (  
        <>        
        {captcha ? (
            <>
            
            <div className="wrapper">                    
                <h3>Security</h3>                
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleChange}
                />         
                {loading &&  <Loader hideProgress />}                           
            </div>
            </>
        ): (
            <>
            {loading ? <Loader /> : ( 
                
                <form className="wrapper" onSubmit={submitHandler}>

                    <h3>Register</h3>  

                    <div 
                        className="parent gap-2 align-items-center" 
                        style={{ marginBottom: 20 }}
                    >
                        <label>                            
                            <input
                                type="file"  
                                style={{ display: 'none' }}
                                name="avatar"                            
                                onChange={onChange}                                                                                                                             
                            />
                            <img 
                                src={avatarPreview} 
                                alt="Avatar Preview" 
                                style={{ width: '59px', height: '59px', borderRadius: '50%' }}
                            />                              
                        </label>

                        <div style={{ flex: 1 }}>
                            <Select
                                label="Title"
                                data={data}
                                value={title}
                                onChange={handleTitle}
                                filled={title}
                                required
                            />
                        </div>

                    </div>

                    <div className="d-flex direction-column gap-2"> 
                        <Input 
                            name="firstName"
                            placeholder="First name *"
                            value={firstName} 
                            onChange={onChange} 
                            filled={firstName}
                            required
                        />
                        <Input 
                            name="lastName"
                            placeholder="Last name *"
                            value={lastName} 
                            onChange={onChange} 
                            filled={lastName}
                            required
                        />
                        <Input 
                            name="email"
                            placeholder="Email *"
                            value={email} 
                            onChange={onChange} 
                            filled={validateEmail(email)}
                            autoCapitalize="none"
                            required
                        /> 
                        <Input 
                            type="password"
                            name="password"                                
                            placeholder="Password (6 characters min) *"
                            value={password}                             
                            onChange={onChange} 
                            filled={isPasswordValid(password)}
                            required
                        />  
                    </div>

                    <button 
                        type="submit"                             
                        className="button booking-button gap-1"
                        disabled={ !title || !firstName || !lastName || !validateEmail(email) || !isPasswordValid(password) }
                    >
                        <PersonAddAltIcon style={{ color: 'white' }} /> Sign Up
                    </button>    

                    <div className="d-flex justify-content-between align-items-center">
                        <small>Already signed up?</small> 
                        <small className="d-flex align-items-center cursor-pointer" onClick={handleLogin}>
                            Login
                            &nbsp; 
                            <button className="icon-button">
                                <LoginIcon />
                            </button> 
                        </small>
                    </div>                
                    
                </form>   
            
            )}
            </>
        )}           
        </>
    )
    
}

export default Register
