import ScubaDivingIcon from "../icons/navigation/ScubaDivingIcon"

const Offer = () => {

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    
    const nextSeason = currentMonth <= 7 
        ? currentYear 
        : currentYear + 1

    return (

        <div 
            style={{ 
                background: '#0B0B45',                 
                padding: '20px 40px 20px 20px', 
                borderRadius: 10, 
                fontSize: 14,
                color: 'white' 
            }}
        >
            
            <div className="parent align-items-center gap-1">
                
                <div>                    
                    <b style={{ color: '#fff' }}>
                        Season {nextSeason} - {nextSeason + 1}, 10% discount, valid until 31st August {nextSeason}
                    </b>
                    <br />
                    <b style={{ color: '#fff200' }}>
                        Season {nextSeason + 1} - {nextSeason + 2}, 10% discount, valid until 31st August {nextSeason + 1}
                    </b>
                    <br />
                    <br />
                    &bull;<small> Available for FIT, group booking, full charter, divers/ non-divers, snorkeling & swimming groups are all welcome</small>

                </div>

                <div className="text-center">

                    <ScubaDivingIcon style={{ fontSize: '3rem', color: 'var(--secondary-color)' }} />

                </div>
            </div>
        
        </div>

    )

}

export default Offer