import { useMediaQuery } from 'react-responsive'
import { cloudinaryURL } from '../../utils'

const Parallax = ({ item, alignLeft }) => {  

  const isMobile = useMediaQuery({ query: `(max-width: 501px)` })

  return (
    <>
    {item && (
      <div 
        className="background-cover d-flex align-items-center" 
        style={{ 
          height: '100vh', 
          backgroundImage: `url(${cloudinaryURL(isMobile ? item.med_url : item.url)})`,   
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }} 
    >
        <strong 
          className={`wrapper sticky ${!alignLeft ? 'text-right' : ''}`} 
          style={{ 
            color: 'white', 
            fontSize: 'clamp(20px, 5vw, 80px)', 
            top: 20,
            textShadow: '2px 2px 4px #333',
            textTransform: 'uppercase'
          }}
        >
          {item.title}
        </strong>
    </div>
    )}
    </>

    

  )

}

export default Parallax