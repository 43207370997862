import { useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { cloudinaryURL } from '../../../utils'
import { useMediaQuery } from 'react-responsive'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './slideshow.css' 

const Slideshow = ({ images }) => {

    const isMobile = useMediaQuery({ query: `(max-width: 501px)` })

  const [ currentIndex, setCurrentIndex ] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 10000) 
    return () => clearInterval(intervalId)
  }, [currentIndex, images.length])

  return (
    <div className="slideshow-container">
        {images.map((image, index) => (
            <div
                key={index}
                className={`slide ${index === currentIndex ? 'active' : ''}`}
            >
                <LazyLoadImage
                    src={cloudinaryURL(isMobile ? image.med_url ? image.med_url : image.url : image.url)} 
                    alt={image.title} 
                    effect="blur"
                    placeholderSrc={cloudinaryURL(image.thumb_url)}
                    className="object-fit"     
                />
            </div>
        ))}
    </div>
  )
}

export default Slideshow
