import { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-date-range'
import { format } from 'date-fns'
import { cloudinaryURL, formatDayMonthDate, formatMyDate } from '../utils'
import { useAlert } from 'react-alert'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getProducts } from '../actions/productActions'
import { getBoats } from '../actions/boatActions'
import { emptyCart } from '../actions/cartActions'
import Pagination from 'react-js-pagination'
import MetaData               from './layout/MetaData'
import CalendarMonthIcon      from './layout/icons/navigation/CalendarMonthIcon'
import KeyboardArrowLeftIcon  from './layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from './layout/icons/arrows/KeyboardArrowRightIcon'
import ArrowDropUpIcon        from './layout/icons/arrows/ArrowDropUpIcon'
import ArrowDropDownIcon      from './layout/icons/arrows/ArrowDropDownIcon'
import FirstPageIcon          from './layout/icons/arrows/FirstPageIcon'
import LastPageIcon           from './layout/icons/arrows/LastPageIcon'
import ArrowForwardIcon       from './layout/icons/arrows/ArrowForwardIcon'
import RefreshIcon            from './layout/icons/play/RefreshIcon'
import TableViewIcon          from './layout/icons/lists/TableViewIcon'
import Visibility             from './layout/icons/visibility/VisibilityIcon'
import Select                 from './layout/forms/Select'
import Radio                  from './layout/forms/Radio'
import Ticker                 from './layout/tools/ticker/Ticker'
import ToolTip                from './layout/tools/ToolTip'
import Loader                 from './layout/tools/Loader'
import CircularProgress       from './layout/tools/circularProgress/CircularProgress'
import BreadCrumbs            from './layout/tools/BreadCrumbs'
import Backdrop               from './layout/tools/Backdrop'
import Product                from './products/Product'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const Trips = () => {      
    
    const dispatch  = useDispatch()  
    const navigate  = useNavigate()
    const alert     = useAlert()   
    const refList   = useRef()
    const params    = new URLSearchParams(window.location.search)
    const _boatName = params.get('boatName')

    const { loading, products, resPerPage, error, filteredProductsCount, finalTrip, seasonOne, seasonTwo } = useSelector( state => state.products )
    const { boats     } = useSelector( state => state.boats )
    const { cartItems } = useSelector( state => state.cart )

    const [ currentPage, setCurrentPage ] = useState(1)  
    const [ boatName,    setBoatName    ] = useState(cartItems[0] ? cartItems[0].boatName : _boatName ? _boatName : '') 
    const [ departure,   setDeparture   ] = useState(cartItems[0] ? cartItems[0].departure : '')
    const [ boatData,    setBoatData    ] = useState([])
    const [ open,        setOpen        ] = useState(false)      
    const [ spaces,      setSpaces      ] = useState('')  
    const [ seasonStart, setSeasonStart ] = useState('')    

    useEffect(() => {    
        if(error) return alert.error('No results') 
        dispatch(getProducts(currentPage, departure, boatName, spaces, seasonStart)) 
        dispatch(getBoats())    
    }, [dispatch, alert, currentPage, departure, error, boatName, spaces, seasonStart])

    useEffect(() => {
        let a = [{
            label: 'All',
            value: ''
        }]
        if (boats) {
            for (let i = 0; i < boats.length; i++){
                a.push({
                    label: boats[i].name,
                    value: boats[i].name
                })
            }
        }
        setBoatData(a)
    }, [boats])

    useEffect(() => {
        const currentPath = window.location.pathname
        navigate(currentPath, { replace: true })
    }, [navigate])
    
    const setCurrentPageNo = (pageNumber) => {        
        setCurrentPage(pageNumber)  
        document.querySelector('.scrollTo').scrollIntoView({ behavior: "smooth" })        
    }

    const handleFullTrips = () => {
        setSpaces(spaces > 0 ? 0 : 1)
        setCurrentPageNo(1)
    }

    const sanitizeInput = (value) => {
        const x = value.replace(/[^0-9]/, '')
        return x
    }

    const handleSpaces = (e) => {
        const value = sanitizeInput(e.target.value)
        setSpaces(value)
        setCurrentPageNo(1)
    }

    const incrementSpaces = (mode) => {
        if (mode === 'increase') {
            setSpaces(spaces ? parseInt(spaces, 10) + 1 : 1)
        } else {
            if (!spaces || spaces === 1) return
            setSpaces(parseInt(parseInt(spaces, 10), 10) - 1)
        }
        setCurrentPage(1)
    }         

    const handleRadioClick = (year) => {        
        setDeparture('')
        setSeasonStart(year)
        setCurrentPageNo(1)
    }

    const reset = () => {
        setDeparture('')
        setBoatName('')
        setSpaces('')
        setCurrentPage(1)        
        setSeasonStart('')     
        dispatch(emptyCart())
        alert.info('Booking reset')
    } 
   
    return (
        <>
        <MetaData title={'Trip schedules'} />

        <div className="main-banner" style={{ height: 480 }}>

            <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
            </span>

            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}>
                    
                <LazyLoadImage
                    src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1701871523/westcoast/pages/_DSC4555-E-2_w9ypz6.jpg')} 
                    alt={'Trips'} 
                    effect="blur"
                    placeholderSrc={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1701994822/westcoast/pages/thumb_q8d4ec.jpg')}
                    className="object-fit"     
                />
                
            </div>

            <div className="container" style={{ height: '100%', position: 'relative', zIndex: 2 }}>

                <Link to="/" className="wrapper-x" style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}>                             
                    <img                    
                        src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724650654/westcoast/logo/logo-large_vnmjgh.png')}
                        alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                        className="logo logo-large"
                        loading="lazy" 
                    />   
                </Link>   

                <div 
                    className="wrapper d-flex direction-column justify-content-end align-items-center gap-2"
                    style={{ height: '100%', paddingBottom: 10 }}
                > 

                    <h1>Liveaboard Trips</h1>

                    <div className="mobile-fullwidth">

                        <div className="d-flex gap-2 mobile-column">
                        
                            <div className="relative">                            

                                <label className="label transparent d-flex align-items-center" onClick={() => setOpen(!open)}  >                           

                                    <input 
                                        className="input transparent filter-input"                                    
                                        value={departure ? formatMyDate(departure) : ''}
                                        placeholder="DEPARTURE"  
                                        readOnly
                                    />

                                    <small>DEPARTURE</small>

                                    <span className="adornment-end" style={{ zIndex: -1 }}>
                                
                                        <CalendarMonthIcon style={{ marginTop: 7, color: '#fff' }} />                            

                                    </span>

                                </label> 

                                {open && 
                                    <>
                                    <Backdrop
                                        invisible
                                        onclick={() => setOpen(false) }  
                                    /> 
                                    <Calendar
                                        className="calendar"
                                        minDate={new Date()}
                                        maxDate={finalTrip ? new Date(finalTrip) : null}
                                        onChange={(date) => {
                                            setDeparture(format(date, 'MM/dd/yyyy')) 
                                            setOpen(false)                                                 
                                            setSeasonStart('')
                                            setCurrentPageNo(1)
                                        }}  
                                    /> 
                                    </>                          
                                }

                            </div>

                            <Select
                                label="BOATS"
                                style={{
                                    background: 'rgba(0,0,0,0.5',
                                    border: 'solid 1px rgba(255,255,255,0.5)',
                                    color: 'white',
                                }}
                                className="transparent filter-input"
                                data={boatData} 
                                value={boatName}
                                onChange={setBoatName} 
                                func={(e) => setCurrentPageNo(1, e.target.value)} 
                            /> 

                            <label className="label transparent">                           

                                <input 
                                    className="input transparent filter-input"                                    
                                    value={spaces}
                                    onChange={handleSpaces}
                                    placeholder="SPACES"  
                                />

                                <small>SPACES</small>

                                <span className="adornment-end" >                                 
                                    <button onClick={() => incrementSpaces('increase')}>
                                        <ArrowDropUpIcon 
                                            style={{ color: 'var(--cta-green)', cursor: 'pointer', position: 'relative', top: '5px'}} 
                                        /> 
                                    </button> 
                                    <button onClick={() => incrementSpaces('decrease')}>
                                        <ArrowDropDownIcon 
                                            style={{ color: 'var(--cta-red)', cursor: 'pointer', position: 'relative', top: '-5px' }} 
                                        />   
                                    </button>   
                                </span>
                            
                            </label> 

                        </div>

                        {seasonOne && (

                            <small 
                                className="d-flex align-items-center"
                                style={{ marginLeft: -14, color: 'white' }}
                            > 
                                <Radio 
                                    onClick={() => handleRadioClick(seasonOne)} 
                                    checked={seasonStart === seasonOne ? true : false}
                                />                            
                                {seasonOne}
                                &nbsp;
                                <ArrowForwardIcon style={{ fontSize: '.85rem', color: 'white' }} />
                                &nbsp;
                                {seasonTwo}
                                                        
                                <Radio 
                                    onClick={() => handleRadioClick(seasonTwo)} 
                                    checked={seasonStart === seasonTwo ? true : false}
                                />                            
                                {seasonTwo}
                                &nbsp;
                                <ArrowForwardIcon style={{ fontSize: '.85rem', color: 'white' }} />
                                &nbsp;
                                {seasonTwo + 1}                            
                            </small>

                        )}
                          
                    </div>

                    <Ticker /> 

                </div>

            </div>            

        </div>       

        <div>

            {loading ? <Loader /> : ( 

                <div className="container">

                    <div className="mobile-parent align-items-center">

                        <BreadCrumbs pageName="Liveaboard schedule" />

                        <div className="d-flex justify-content-end align-items-center wrapper-x">
                        
                            <Link 
                                to={`/trip-list?boatName=${boatName}`} 
                                className="d-flex align-items-center gap-1"
                            >
                                <Visibility style={{ color: '#999' }} />
                                <small className="whitespace-nowrap">switch to classic view</small>
                                <TableViewIcon />
                            </Link>
                        </div>

                    </div>                    

                    <div className="wrapper-x mobile-parent align-items-center">

                        <div className="mobile-fullwidth">
                    
                            <h2 className="large-header d-flex align-items-center gap-1" style={{ margin: 0 }}>
                                <CalendarMonthIcon style={{ fontSize: '2rem', color: 'var(--secondary-color)' }} />
                                {boatName ? boatName : 'All Liveaboard Trips'}                                    
                                <sub><small>({filteredProductsCount})</small></sub>
                            </h2>

                            {/* <div>
                                <small>
                                    {!seasonStart ? (
                                        <>
                                        <b>{departure ? formatDayMonthDate(departure) : formatDayMonthDate(Date.now())}</b> onwards
                                        </>
                                    ) : (
                                        <>
                                        season start: <b>{seasonStart}</b>
                                        </>
                                    )}                                    
                                </small>
                                {spaces ? (
                                    <p>
                                        <small className="d-flex align-items-center">
                                            Spaces: 
                                            &nbsp;
                                            <span 
                                                className="d-flex justify-content-center align-items-center"
                                                style={{  
                                                    border: 'solid 1px var(--border-color)',
                                                    borderRadius: '50%',
                                                    width: 50, height: 50
                                                }}
                                            >
                                                {spaces ? spaces : 0}
                                            </span>
                                        </small>
                                    </p>                        
                                ) : (
                                    null
                                )}    
                            </div>                     */}

                        </div>

                        <div className="d-flex align-items-center scrollTo">

                            <div className="d-flex align-items-center" style={{ marginRight: -10 }}>
                                <small>hide full trips</small>
                                <Radio onClick={handleFullTrips} checked={spaces === 0 || spaces === '' ? false : true} />
                            </div>  

                            {(currentPage > 1 || departure || boatName || seasonStart || spaces) ? (
                                <ToolTip title="Reset">
                                    <button 
                                        className="icon-button" 
                                        aria-label="Reset"
                                        onClick={reset} 
                                        style={{ background: 'var(--primary-color)', marginLeft: 10 }}
                                    >
                                        <RefreshIcon
                                            aria-label="Reset"                                                   
                                            style={{ color: 'white' }}                                                 
                                        />
                                    </button>
                                </ToolTip>
                            ) : null}

                        </div>    

                    </div>

                    <div> 

                        <div 
                            className="d-flex flex-wrap justify-content-between align-items-end wrapper-x" 
                            style={{ marginBottom: 10 }}
                        >
                            <div>
                                <small>
                                    {!seasonStart ? (
                                        <>
                                        <b>{departure ? formatDayMonthDate(departure) : formatDayMonthDate(Date.now())}</b> onwards
                                        </>
                                    ) : (
                                        <>
                                        season start: <b>{seasonStart}</b>
                                        </>
                                    )}                                    
                                </small>
                                {spaces ? (
                                    <p>
                                        <small className="d-flex align-items-center">
                                            Spaces: 
                                            &nbsp;
                                            <span 
                                                className="d-flex justify-content-center align-items-center"
                                                style={{  
                                                    border: 'solid 1px var(--border-color)',
                                                    borderRadius: '50%',
                                                    width: 50, height: 50
                                                }}
                                            >
                                                {spaces ? spaces : 0}
                                            </span>
                                        </small>
                                    </p>                        
                                ) : (
                                    null
                                )}    
                            </div>   
                            
                            {products && products.length && resPerPage < filteredProductsCount ? (
                            
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={resPerPage}
                                    totalItemsCount={filteredProductsCount}                        
                                    onChange={setCurrentPageNo}   
                                    nextPageText={<KeyboardArrowRightIcon />}  
                                    prevPageText={<KeyboardArrowLeftIcon />} 
                                    firstPageText={<FirstPageIcon />} 
                                    lastPageText={<LastPageIcon />}  
                            
                                />
                                
                            ): null}  

                        </div> 

                        <ul className="products" ref={refList}>

                            {products && products.length  ? (
                            
                            products.map((product, i) => (
                                
                                <Product 
                                    key={product._id} 
                                    products={products} 
                                    product={product} 
                                    index={i}
                                    seasonTwo={seasonTwo}
                                />                            
                            
                                )) 
                            ) : (

                                <li style={{ width: '100%' }}> No results ...</li>
                        
                        )} 

                        </ul>
                        

                        <div style={{ height: 30, marginBottom: 40 }}>
                        
                            {products && products.length && resPerPage < filteredProductsCount ? (
                            
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={resPerPage}
                                    totalItemsCount={filteredProductsCount}                        
                                    onChange={setCurrentPageNo}   
                                    nextPageText={<KeyboardArrowRightIcon />}  
                                    prevPageText={<KeyboardArrowLeftIcon />} 
                                    firstPageText={<FirstPageIcon />} 
                                    lastPageText={<LastPageIcon />}  
                            
                                />
                                
                            ): null}  

                        </div>           

                    </div>

                </div>

            )} 

        </div>
        </>
    )
}

export default Trips