import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { calculateTotalSpaces, formatDateDay, formatDateMonth, formatDateYear, formatPrice, discountValid } from '../../utils'
import { addItemToCart, emptyCart } from '../../actions/cartActions'
import DirectionsBoatIcon from '../layout/icons/navigation/DirectionsBoatIcon'
import ArrowForwardIcon from '../layout/icons/arrows/ArrowForwardIcon'
import OpenCurrencyExchange from '../layout/tools/OpenCurrencyExchange'
import PercentIcon from '../layout/icons/zoom/PercentIcon'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import ToolTip from '../layout/tools/ToolTip'
import './product.css'

const Product = ({ product, products, index, hideLink, seasonTwo }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const alert    = useAlert()

    const { user } = useSelector( state => state.auth )  
    const { cartItems } = useSelector( state => state.cart )
   
    const renderMonth = (prevProduct, product) => {

        const month = new Date(product.departure).getMonth() + 1
        const prevMonth = prevProduct !== 0 ? new Date(prevProduct.departure).getMonth() + 1 : 0
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        const output = (month === 1 && month < prevMonth) || (month > prevMonth)
            ? <li>
                <h3 className="widget-header" style={{ fontSize: 20, marginBottom: 20 }}>
                    {monthNames[month - 1]}
                </h3>
              </li>
            : null

        return output     

    }

    const handleBooking = () => {    
        if (cartItems[0]) {
            if (product._id !== cartItems[0].tripId) {
                dispatch(emptyCart())                
            }
        } 
        dispatch(addItemToCart({
            id: product._id,   
            discount: discountValid(product.seasonStart, seasonTwo) ? 10 : 0,
            guestArray: [],
        }))       
        alert.info('New booking created')
        navigate('/booking')
    }    

    return (        
        <>
        {index || index === 0 ? (
            renderMonth(index !== 0 ? products[index - 1] : 0, products[index])
        ) : (null)}

        <li className="product">

            <div className="align-items-center gap-2 mobile-parent">

                <div className="d-flex align-items-center gap-2 justify-content-between mobile-fullwidth" style={{ width: '50%' }}>
                    <div style={{ alignSelf: 'flex-start' }}>
                        <small style={{ padding: 5, display: 'inline-block', border: 'solid 1px var(--border-color)', borderRadius: '15px' }}>4 Days / 4 Nights</small>
                        <br />
                        <b style={{ fontSize: 28 }}>{formatDateDay(product.departure)}</b>
                        &nbsp;
                        <span style={{ color: '#999', textTransform: 'uppercase' }}>{formatDateMonth(product.departure)}</span>
                        <br />
                        <span>{formatDateYear(product.departure)}</span> 
                        
                        {user && user.role === 'admin' && (
                            <ToolTip title="edit">
                                <Link to={`/admin/product/${product._id}`} style={{ marginLeft: 5 }}>
                                    <EditOutlinedIcon style={{ fontSize: '1rem' }} />                        
                                </Link>  
                            </ToolTip>
                        )}
                        <br />
                        <strong style={{ fontSize: 10, color: 'orange' }}>{product.code}</strong>
                                    
                    </div>

                    <div className="mobile">
                        <button className="icon-button" disabled>
                            <ArrowForwardIcon />
                        </button>
                    </div>

                    <div className="mobile" style={{ alignSelf: 'flex-start' }}>
                        <small style={{ padding: 5, display: 'inline-block', border: 'solid 1px var(--border-color)', borderRadius: '15px' }}>4 Days / 4 Nights</small>
                        <br />
                        <b style={{ fontSize: 28 }}>{formatDateDay(product.return)}</b>
                        &nbsp;
                        <span style={{ color: '#999', textTransform: 'uppercase' }}>{formatDateMonth(product.return)}</span>
                        <br />
                        <span>{new Date(product.return).getFullYear()}</span>
                    </div>
                </div>               

                <div style={{ width: '50%' }}>

                    <div className="d-flex align-items-center">
                        <DirectionsBoatIcon style={{ fontSize: '1rem' }} />
                        &nbsp;
                        <small>{product.boatName}</small>
                    </div>

                    <p className="whitespace-nowrap"><b style={{ fontSize: 18 }}>{product.destination}</b></p>
                    <p>
                        <small className="d-flex align-items-center whitespace-nowrap">
                            Tab-Lamu &nbsp; 
                            <ArrowForwardIcon style={{ fontSize: '1rem' }} /> 
                            &nbsp; Tab-Lamu
                        </small>
                    </p>

                    {!hideLink && (
                        <Link to={`/trip/${product._id}`} className="link d-flex align-items-center">
                            Itinerary details
                            &nbsp; 
                            <ArrowForwardIcon style={{ fontSize: '1rem' }} /> 
                        </Link>  
                    )}   

                    {discountValid(product.seasonStart, seasonTwo) && (

                        <span className="d-flex align-items-center relative" style={{ marginTop: 8, borderRadius: 20, background: 'var(--cta-required)', padding: '4px 10px 4px 50px' }}>
                            <button 
                                className="icon-button" 
                                style={{ width: 45, height: 45, background: '#FF553E', position: 'absolute', left: 0, top: 0 }}
                                disabled
                            >
                                <PercentIcon style={{ color: 'white' }} />                            
                            </button>
                            <div style={{ color: '#FF553E' }}>
                                <b>10% discount</b>
                                <br />
                                <small className="whitespace-nowrap">                                    
                                    31st August
                                    &nbsp;
                                    {new Date().getMonth() > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()}
                                </small>
                            </div>
                        </span>    

                    )}

                </div>

            </div>

            <div className="d-flex align-items-center gap-2">

                <div className="d-flex gap-2 align-items-center">
                    <b 
                        style={{ fontSize: 18, width: 50, height: 50, borderRadius: '50%', border: 'solid 1px var(--primary-color)' }}
                        className="primary-color d-flex justify-content-center align-items-center"
                    >
                    {calculateTotalSpaces(product.cabins)}
                    </b>
                    <span>
                        <b className="primary-color">Spaces</b>
                        <br />
                        <small className="primary-color">Available</small>
                    </span>
                </div>

                <div className="d-flex relative" style={{ gap: 5 }}>

                    <div style={{ position: 'relative', top: 4 }}>
                        <small>from</small>
                        <br />
                        <b style={{ position: 'relative', top: -5 }}>THB</b>
                    </div>
                    
                    <span style={{ fontSize: 32 }}>
                        {formatPrice(product.cabins.budget.price, 'none')}   
                        <OpenCurrencyExchange 
                            value={product.cabins.budget.price 
                                ? product.cabins.budget.price 
                                : 1} 
                            style={{ position: 'absolute', top: '-100%', right: 0 }}
                        />
                    </span> 
                
                </div> 

                {calculateTotalSpaces(product.cabins) ? (
                    <button
                        className="button"                        
                        onClick={handleBooking}
                        style={{ width: 100, margin: 0 }}
                    >
                        Book
                    </button>  
                ) : (
                    <button 
                        className="button" 
                        style={{ width: 100, margin: 0 }}
                        disabled
                    >
                        Full
                    </button>  
                )}

            </div>
                                    
        </li>
        </>
    )

}

export default Product
