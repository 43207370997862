import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { cloudinaryURL } from '../utils'
import CircularProgress from './layout/tools/circularProgress/CircularProgress'
import CalendarMonthIcon from './layout/icons/navigation/CalendarMonthIcon'
import Slideshow from './images/slideshow/Slideshow'
import Ticker from './layout/tools/ticker/Ticker'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Banner = ({ img, images, title, subTitle, boatName, hideButton, height, ticker }) => {

    const isMobile = useMediaQuery({ query: `(max-width: 501px)` })

    return (

        <div className="main-banner" style={{ height: height ? height : 480 }}>

            <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
            </span>

            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}>

                {images ? (

                    <Slideshow images={images} />

                ) : (                
                        
                    <LazyLoadImage
                        src={cloudinaryURL(isMobile ? img.med_url ? img.med_url : img.url : img.url)} 
                        alt={title} 
                        effect="blur"
                        placeholderSrc={cloudinaryURL(img.thumb_url)}
                        className="object-fit"     
                    />                    
                    
                )}  

            </div>          

            <div style={{ height: '100%', position: 'relative', zIndex: 2 }}>

                <Link to="/" className="wrapper-x" style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}>                             
                    <img                    
                        src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724650654/westcoast/logo/logo-large_vnmjgh.png')}
                        alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                        className="logo logo-large"
                        loading="lazy" 
                    />   
                </Link>  

                <div 
                    className="wrapper d-flex direction-column justify-content-end align-items-end"
                    style={{ height: '100%', paddingBottom: ticker ? 10 : 40 }}
                > 

                    <div>                                   

                        <h1 className="text-right">{title}</h1>   

                        {subTitle && (
                            <h2>{subTitle}</h2>  
                        )}                       

                    </div>  

                    {!hideButton && (
                        <Link 
                            to={`/trip-schedules${boatName ? `?boatName=${boatName}` : ''}`} 
                            style={{ alignSelf: 'flex-end' }}
                        >
                            <button className="button gap-1">
                                <CalendarMonthIcon style={{ color: 'white' }} />
                                Trip Schedules
                            </button>
                        </Link>    
                    )} 

                    {ticker && (
                        <Ticker /> 
                    )}                         

                </div>

            </div>                  

        </div>    

    )

}

export default Banner