import { Link } from "react-router-dom"

const BreadCrumbs = ({ linkOne, labelOne, pageName }) => {

    return (
        <div 
            className="wrapper d-flex align-items-center" 
            style={{ paddingTop: 10, paddingBottom: 10 }}
        >
            <Link 
                to="/" 
                style={{ color: 'var(--primary-color)', fontSize: 12 }}
            >
                Home
            </Link>
            &nbsp; | &nbsp;
            {linkOne && (
                <>
                <Link 
                    to={linkOne} 
                    style={{ color: 'var(--primary-color)', fontSize: 12 }}
                >
                    {labelOne}
                </Link>
                &nbsp; | &nbsp;
                </>
            )}   
            <small>{pageName}</small>     
        </div>
    )

}

export default BreadCrumbs