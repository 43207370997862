import './forms.css'

const Radio = ({ onClick, checked, callback, disabled }) => {    

    return (
        <>
        <span 
            className="radio-button-container"
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            onClick={() => {
                if(!disabled) {
                    onClick(!checked)
                    if(callback) callback()
                }
            }}
        >

            <span className={`radio-button ${checked ? 'checked' : ''}`} />

        </span>         
        </>
    )

}

export default Radio